import React, { Component } from 'react';
import { Link } from 'gatsby';
import { Input, Button } from 'reactstrap';
import styled from 'styled-components';
import { Offline, Online } from 'react-detect-offline';
import { navigate } from 'gatsby-link';

import trackEvent from '../components/analytics';
import Layout, {
  Player,
  ViewButtonNext,
  ViewButtonPrevious,
  ShareButtons,
} from '../components/layout';

import SEO from '../components/seo';
import Sound from '../audio/di/voixoff_DI_2.mp3';
import Background from '../images/login.jpg';

const ViewArticle = styled.article`
  display: flex;
  flex: 1 0 auto;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  overflow-y: auto;
  max-width: 100%;
  background-image: url(${Background});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
`;

const ViewForm = styled.form`
  display: flex;
  flex: 1 1 auto;
  max-width: 100%;
  flex-flow: column nowrap;
  align-items: center;
  justify-items: center;
  justify-content: center;
  overflow-y: auto;
  padding: 0 6rem 0 40%;
  text-align: center;
  animation: bounceIn;
  animation-duration: 0.5s;

  > * {
    margin: 0.2rem 0;
  }
`;

class Index extends Component {
  constructor(props) {
    super(props);
    this.state = { name: '', phone: '' };
    this.onChangeName = this.onChangeName.bind(this);
    this.onChangePhone = this.onChangePhone.bind(this);
  }

  onChangeName = (e) => {
    this.setState({ name: e.target.value });
  };
  onChangePhone = (e) => {
    this.setState({ phone: e.target.value });
  };

  addUser = () => {
    var myHeaders = new Headers();
    myHeaders.append('x-api-key', process.env.GATSBY_APPSYNC_KEY);
    myHeaders.append('Content-Type', 'application/json');

    var graphqlQuery = JSON.stringify({
      query:
        'mutation createPerson($createpersoninput: CreatePersonInput!) {createPerson(input: $createpersoninput) {phone name}}',
      variables: {
        createpersoninput: { name: this.state.name, phone: this.state.phone },
      },
    });

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: graphqlQuery,
      redirect: 'follow',
    };

    fetch(process.env.GATSBY_APPSYNC_ENDPOINT, requestOptions)
      .then((response) => response.text())
      // .then((result) => console.log(result))
      // .catch((error) => console.log('error', error))
      .then(navigate('/di/home'));
  };

  render() {
    return (
      <Layout location={typeof window !== 'undefined' ? location : null}>
        <SEO pathname="/di" />
        <ViewArticle>
          <ViewForm>
            <Input
              type="text"
              name="name"
              onChange={this.onChangeName}
              placeholder="Ton nom (optionel)"
            />
            <Input
              type="text"
              name="phone"
              placeholder="Ton téléphone (optionel)"
              onChange={this.onChangePhone}
            />
            <Online>
              <Button
                color="success"
                disabled={
                  this.state.name === '' || this.state.phone === '' ? true : false
                }
                onClick={() => {
                  this.addUser();
                  trackEvent('Form 1 page', 'User validate', 'yes');
                }}
              >
                <i className="fa fa-check" /> Valider
              </Button>
            </Online>
            <Offline>
              <strong>Réseau indisponible</strong>
              <br />
              <Button disabled>
                <i className="fa fa-fa-times" /> Valider
              </Button>
            </Offline>
            <Link to="/">
              <ViewButtonPrevious
                color="warning"
                onClick={() => {
                  trackEvent('Form 1 page', 'User went back', 'yes');
                }}
              >
                <i className="fa fa-chevron-left fa" />
              </ViewButtonPrevious>
            </Link>
            <Link to="/di/home">
              <ViewButtonNext
                color="warning"
                onClick={() => {
                  trackEvent('Form 1 page', 'User went next', 'yes');
                }}
              >
                <i className="fa fa-chevron-right" />
              </ViewButtonNext>
            </Link>
          </ViewForm>
        </ViewArticle>
        <Player sound={Sound} />
        <ShareButtons />
      </Layout>
    );
  }
}

export default Index;
